<template>
    <div class="company-contain">
        <div class="head-box">
            <div class="title">报名列表</div>
        </div>
        <div class="table-box">
            <el-table
                    :data="taskList"
                    class="college-table"
                    style="width: 100%;overflow: hidden"
                    size="medium"
                    :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa',}"
                    :cell-style="{fontSize: '12px',color: '#333'}"
            >
                <el-table-column
                        prop="user_id"
                        label="序号"
                        width="140"
                        align="center"
                        class-name="student-name"
                >
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.user_id}}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="username" label="账号" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <div class="showing">
                            <img class="imgBox"
                                 :src="scope.row.head_portrait?scope.row.head_portrait:require('../../../assets/img/image/user_img.png')">
                            <span class="showing-name">{{scope.row.username?scope.row.username:scope.row.nickname}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="account_link" label="账号链接" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <span v-if="apply == 3" @click="goToLink(scope.row.account_link)" class="student-link">{{scope.row.account_link ? '点击查看' :'暂无'}}</span>
                        <span v-else @click="goToLink(scope.row.index_url)" class="student-link">{{scope.row.index_url ? '点击查看' :'暂无'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="all_fans_num" label="粉丝数" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.all_fans_nums?cope.row.all_fans_num:scope.row.follower_count}}</span>
                    </template>
                </el-table-column>
                <el-table-column v-if="apply == 3" label="合作情况" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.task_accept_state ==0" size="small">未操作
                        </span>
                        <span class="apply-text" v-if="scope.row.task_accept_state ==1" size="small">同意合作
                        </span>
                        <span class="disApply-text" v-if="scope.row.task_accept_state ==2" size="small">拒绝
                        </span>
                    </template>
                </el-table-column>
                <el-table-column v-else label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" v-if="scope.row.state ==0" size="small" @click="eidtState(2)">通过
                        </el-button>
                        <el-button type="text" v-if="scope.row.state ==0" size="small" @click="eidtState(1)">不通过
                        </el-button>
                        <span v-if="scope.row.state ==2">已通过</span>
                        <span v-if="scope.row.state ==1">未通过</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-dialog
                title="报名"
                :visible.sync="dialogVisible"
                width="30%"
                :before-close="handleClose">
            <span v-if="this.state ==2">确定该账号报名通过？确定后将无法修改</span>
            <span v-if="this.state ==1">确定该账号报名不通过？确定后将无法修改</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="changeState">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: 'taskPublish',
        data() {
            return {
                task_id: "",
                taskList: [],
                state: "",
                dialogVisible: false,
                apply: '',
            }
        },
        mounted() {
            this.getParam();
        },
        methods: {
            getParam() {
                this.task_id = this.$route.query.task_id;
                this.apply = this.$route.query.apply;
                if (this.apply == 3) {
                    this.getAcceptList();
                } else {
                    this.getList();
                }
            },
            // 获取列表
            getList() {
                let param = {
                    task_id: this.task_id
                };
                this.$httpcompany.axiosGetBy(this.$api.enrollList, param, res => {
                    if (res.code == 200) {
                        this.taskList = res.list;
                    }
                });
            },
            getAcceptList() {
                let param = {
                    task_id: this.task_id
                };
                this.$httpcompany.axiosGetBy(this.$api.intelligent_getAcceptList, param, res => {
                    if (res.code == 200) {
                        this.taskList = res.data;
                    }
                });
            },
            //是否通过
            eidtState(val) {
                this.state = val;
                this.dialogVisible = true;
            },
            changeState() {
                let param = {
                    task_id: this.task_id,
                    state: this.state
                };
                this.$httpcompany.axiosGetBy(this.$api.enrollState, param, res => {
                    if (res.code == 200) {
                        this.$message({
                            type: "success",
                            message: res.msg
                        })
                        this.getList();
                        this.handleClose();
                        this.state = "";
                    } else {
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000
                        });
                    }
                });
            },
            handleClose() {
                this.dialogVisible = false;
            },
            goToLink(url) {
                if (url) {
                    window.open(url, '_blank')
                }
            }
        },

    }
</script>

<style scoped lang="scss">
    .company-contain {
        height: calc(100vh - 100px);
        background-color: #ffffff;

        .head-box {
            display: flex;
            justify-content: space-between;
            height: 40px;
            padding: 10px 30px 0 20px;

            .title {
                margin-top: 10px;
            }
        }

        .divide {
            padding: 0 20px;
        }

        .filter-box {
            .box1 {
                padding: 0 20px 10px 20px;
                height: 40px;
                display: flex;

                span {
                    margin-top: 8px;
                    line-height: 32px;
                    display: inline-block;
                    width: 80px;
                    height: 32px;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }

        .showing {
            display: flex;
            align-items: center;
            /*width: 30px;*/
            height: 30px;
            margin-left: 100px;

            img {
                display: inline-block;
                height: 30px;
                width: 30px;
                margin-right: 10px;
                border-radius: 50%;
            }

            .showing-name {
                max-width: 260px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .student-link {
            cursor: pointer;
        }

        .apply-text {
            color: #00BD1B;
        }

        .disApply-text {
            color: #FF0000;
        }
    }
</style>
